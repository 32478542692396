import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface State {
  searchText: string; // \todo\wip
}

const initialState: State = {
    searchText: ""
};

const slice = createSlice({
    name: "locationSearch",
    initialState,
    reducers: {
        submitSearch: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.searchText = payload;
        }
    }
});

export default slice.reducer;
export const { submitSearch } = slice.actions;

