import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import { ThunkAction } from 'redux-thunk';

import locationSearchReducer from "./locationsearch";

const rootReducer = combineReducers({
    locationSearch: locationSearchReducer
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    //preloadedState,
});

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
//export type Thunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
